import classNames from 'classnames';
import { Link } from 'react-router-dom';

const BoxCard = ({ children, link, className, onClick }) => {
  const classes = classNames([
    'bg-white rounded-10 p-4 h-full flex flex-col',
    className
  ])

  if (link) {
    // Check if it's an external link
    const isExternal = link.startsWith('//') || link.startsWith('http');
    
    if (isExternal) {
      return (
        <a 
          href={link}
          className={classes}
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }
    
    // Internal link handling remains the same
    return (
      <Link to={link} className={classes}>
        {children}
      </Link>
    );
  }

  return <div className={classes}>{children}</div>
}

export default BoxCard
